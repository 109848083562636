<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Enrollment"
              url="/pages/masterlists/training-management/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-row-click="(v) => onEdit(v)"
              @on-update-cancel="(v) => loadTrainingManagements()"
              @on-search="(v) => (keyword = v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-status-changed="(v) => (status = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in dataColumns"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import trainingManagementApi from '@api/training_managements';
import { queryParameters } from '@/schema';

export default {
  name: 'TrainingManagementsPage',
  components: {},
  data: () => ({
    dataSource: [],
    dataColumns: [
      { caption: 'Training Code', field: 'training_uuid' },
      { caption: 'Name', field: 'training_name' },
      { caption: 'Employee', field: 'preview_display_name' },
      { caption: 'Status', field: 'exam_status' }, // New, Failed, Achieved, Cancel
      { caption: 'Due Date', field: 'preview_exam_date' },
      { caption: 'Achieved Date', field: 'preview_certificate_start_date' },
      { caption: 'Expiry Date', field: 'preview_certificate_expired_date' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    status: 'O',
    keyword: '',
  }),
  watch: {
    page(v) {
      this.loadTrainingManagements();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadTrainingManagements();
      }
    },
    status(v) {
      this.loadTrainingManagements();
    },
    keyword(v) {
      this.loadTrainingManagements();
    },
  },
  mounted() {
    this.loadTrainingManagements();
  },
  methods: {
    loadTrainingManagements() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: this.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      if (this.keyword) {
        params.filter = this.keyword;
      }

      trainingManagementApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            for (const item of data) {
              item.preview_exam_date = this.displayDateTimeFromUnix(
                item.exam_date
              );

              item.preview_certificate_start_date =
                this.displayDateTimeFromUnix(item.certificate_start_date);

              item.preview_certificate_expired_date =
                this.displayDateTimeFromUnix(item.certificate_expired_date);

              let display_name = '';
              if (item.user && item.user.data) {
                const user = item.user.data;

                display_name = user.first_name;

                if (user.last_name !== '') {
                  display_name += ` ${user.last_name}`;
                }
              }

              item.preview_display_name = display_name;
            }

            this.dataSource = data;

            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit(data) {
      const url = `/pages/masterlists/training-management/view?id=${data.key}`;
      this.$router.push(url);
    },
    onDelete(event) {
      trainingManagementApi
        .delete(event)
        .then(() => {
          this.loadTrainingManagements();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
